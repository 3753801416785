import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import cn from "classnames";

const MisteryBox = () => {
  const [misteryHitTimes, setMisteryHitTimes] = useState(0);
  const [misteryHit, setMisteryHit] = useState(false);
  const [misteryFound, setMisteryFound] = useState(false);
  const [coinAnimations, setCoinAnimations] = useState(0);
  const coinAnimationJSX = useRef(
    <CoinAnimationWrap>
      <CoinWrap>
        <Coin />
      </CoinWrap>
    </CoinAnimationWrap>
  );

  useEffect(() => {
    if (misteryHit) {
      const timeout = setTimeout(() => {
        setMisteryHit(false);
      }, 150);

      setCoinAnimations((animationCount) => animationCount + 1);

      return () => clearTimeout(timeout);
    }
  }, [misteryHit]);

  return (
    <>
      {coinAnimations > 0 && coinAnimationJSX.current}
      {coinAnimations > 1 && coinAnimationJSX.current}
      {coinAnimations > 2 && coinAnimationJSX.current}
      {coinAnimations > 3 && coinAnimationJSX.current}
      {coinAnimations > 4 && coinAnimationJSX.current}
      {coinAnimations > 5 && coinAnimationJSX.current}
      {coinAnimations > 6 && coinAnimationJSX.current}
      {coinAnimations > 7 && coinAnimationJSX.current}

      {misteryFound && (
        <ContactsWrap>
          <ContactsInner>
            <p>+359 886 632 811</p>
            <p>
              <a
                href="mailto:iliev.mario@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                iliev.mario@gmail.com
              </a>
            </p>
            <p>
              <a
                href="https://www.linkedin.com/in/mario-iliev/"
                target="_blank"
                rel="noreferrer"
              >
                LinkedIn
              </a>
            </p>

            <p>
              <a
                href="https://www.facebook.com/marioiliev.89/"
                target="_blank"
                rel="noreferrer"
              >
                Facebook
              </a>
            </p>
          </ContactsInner>
        </ContactsWrap>
      )}

      <Wrap
        className={cn({ hit: misteryHit, clickable: !misteryFound })}
        onClick={() => {
          if (misteryHitTimes < 7) {
            setMisteryHit(Date.now());
            setMisteryHitTimes(misteryHitTimes + 1);
          } else {
            setMisteryFound(true);
          }
        }}
      />
    </>
  );
};

export default MisteryBox;

const Wrap = styled.div`
  position: absolute;
  bottom: 300px;
  left: 6%;
  width: 57px;
  height: 57px;
  background-image: url(./images/svg/mistery-box.svg);
  background-size: 57px;
  background-repeat: no-repeat;

  &.clickable {
    cursor: pointer;
  }

  &.hit {
    background-image: url(./images/svg/mistery-box-hit.svg);
  }
`;

const CoinAnimationWrap = styled.div`
  position: absolute;
  display: flex;
  bottom: 327px;
  left: 6%;
  width: 57px;
  height: 170px;
  justify-content: center;
`;

const CoinWrap = styled.div`
  position: absolute;
  width: 17px;
  height: 30px;
  left: 21px;
  bottom: 0;
  animation: coin-jump 1s ease forwards;
`;

const Coin = styled.div`
  width: 17px;
  height: 30px;
  background-image: url(./images/svg/coin-sprite.svg);
  background-repeat: no-repeat;
  background-size: auto 30px;
  animation: coin-spin 0.3s linear infinite;
`;

const ContactsWrap = styled.div`
  position: absolute;
  bottom: 300px;
  left: 6%;
  width: 215px;
  height: 207px;
  margin-left: -70px;
  opacity: 0;
  animation: fade-in 0.3s ease forwards;
`;

const ContactsInner = styled.div`
  position: relative;
  background-color: #fff;
  border-radius: 5px;
  color: #5e80fd;
  padding: 20px;
  font-size: 15px;

  a {
    color: #5e80fd;

    &:hover {
      color: #71be11;
    }
  }

  p {
    margin: 0;
    padding-top: 10px;

    &:first-child {
      padding-top: 0;
    }
  }

  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: 89px;
    bottom: -10px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff;
  }
`;
