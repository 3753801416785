import { BrowserRouter } from "react-router-dom";
import { StoreMe } from "store-me";
import ReactDOM from "react-dom";

import { generateState, generateInitialGrid } from "./utils/chartUtils";
import getFigurePositions from "./utils/getFigurePositions";
import local_storage from "./utils/local_storage";
import _constants from "./components/_constants";
import GlobalStyle from "./styles/GlobalCSS";
import App from "./App";

const gridColumns = _constants.candlesCount;
const gridRows = _constants.candlesHeight;

const initialState = {
  ...generateState(generateInitialGrid(gridColumns, gridRows), true),
  resourcesLoaded: false,
  moveCandles: false,
  addCandle: false,
  gridColumns,
  gridRows,
  language: local_storage.get("language") || "bg",
};

initialState.prevTopPosition = getFigurePositions(
  initialState.firstIndexByColumn,
  true
);
initialState.targetTopPosition = initialState.prevTopPosition;
initialState.figureGrid = initialState.grid;

ReactDOM.render(
  <StoreMe initialState={initialState}>
    <GlobalStyle />
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StoreMe>,
  document.getElementById("root")
);
