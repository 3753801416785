import { useStoreMe, setStoreMe } from "store-me";
import styled from "styled-components";
import { useEffect } from "react";
import cn from "classnames";

import local_storage from "../utils/local_storage";
import ChartAnimation from "./ChartAnimation";
import EvilMushroom from "./EvilMushroom";
import FigureJump from "./FigureJump";
import _constants from "./_constants";
import MisteryBox from "./MisteryBox";

const GoodBye = () => {
  const { language } = useStoreMe("language");

  useEffect(() => {
    local_storage.set("language", language);
  }, [language]);

  return (
    <Wrap>
      <Background>
        <Ground />
        <AboveGroundLevel>
          <CloudSingle />
          <CloudDouble />
          <CloudTriple />

          <BricksWithCoin />

          <HillBig />
          <BushSingle />
          <HillSmall />
          <BushDouble />

          <SmallPipe />
          <BigPipe />

          <EvilMushroomWrap>
            <EvilMushroom />
          </EvilMushroomWrap>
        </AboveGroundLevel>
      </Background>

      <ForeGround>
        <ContentWrap>
          <MisteryBox />

          <Description>
            {language === "bg" && (
              <>
                <p>
                  <strong>Супер, Марио си тръгва.</strong>
                </p>
                <p>
                  Дойде време да мина на следващото ниво.
                  <br />
                  За мен беше истинско удоволствие да работим и създаваме
                  страхотни неща заедно.
                  <br />
                  За трите ми години с вас получих наистина много - сдобих се с
                  ценни приятелства и работих със страхотни професионалисти.
                  Благодаря ви!
                </p>
                <p>Контактите ми може да намерите някъде на тази страница :)</p>
              </>
            )}

            {language === "en" && (
              <>
                <p>
                  <strong>Super, Mario is leaving.</strong>
                </p>
                <p>
                  It's time for me to go to the next level.
                  <br />
                  It was a real pleasure for me to work with you and to create
                  amazing stuff together.
                  <br />
                  In my three years here I got a lot - valuable friendships and
                  the opportunity to work with great professionals. Thank you!
                </p>
                <p>You can find my contacts somewhere on this page :)</p>
              </>
            )}

            <Languages>
              <Language
                className={cn({ active: language === "bg" })}
                onClick={() => setStoreMe({ language: "bg" })}
              >
                BG
              </Language>
              <Language
                className={cn({ active: language === "en" })}
                onClick={() => setStoreMe({ language: "en" })}
              >
                EN
              </Language>
            </Languages>
          </Description>

          <ChartWrap>
            <ChartInner>
              <ChartAnimation />
              <FigureJump />
            </ChartInner>
            <ChartLegend>
              <LegendYear>
                <span>2019</span>
              </LegendYear>
              <LegendYear>
                <span>2020</span>
              </LegendYear>
              <LegendYear>
                <span>2021</span>
              </LegendYear>
              <LegendYear>
                <span>2022</span>
              </LegendYear>
            </ChartLegend>
          </ChartWrap>
        </ContentWrap>
      </ForeGround>
    </Wrap>
  );
};

export default GoodBye;

const Wrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  animation: fade-in 0.3s ease forwards;
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Ground = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${_constants.groundHeight}px;
  background: url(./images/svg/ground.svg) repeat;
  background-size: 100px;
`;

const ForeGround = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - ${_constants.groundHeight}px);
  z-index: 1;
  align-items: center;
  justify-content: center;
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #fff;
`;

const Description = styled.div`
  position: relative;
  text-align: center;
  padding: 20px 20px 40px 20px;
  background-color: #fff;
  border-radius: 10px 10px 0 0;
  line-height: 22px;
  color: #3a3a3a;
  width: ${_constants.contentWidth}px;

  p {
    padding-top: 10px;
    margin: 0;

    &:first-child {
      padding-top: 0;
    }

    strong {
      font-size: 17px;
    }
  }
`;

const Languages = styled.div`
  position: absolute;
  display: flex;
  top: 20px;
  right: 20px;
`;

const Language = styled.div`
  font-size: 13px;
  margin-left: 15px;
  cursor: pointer;
  font-weight: bold;

  &:first-child {
    margin-left: 0;
  }

  &.active {
    color: #5f80fd;
  }
`;

const ChartWrap = styled.div`
  background-color: #fff;
  border-radius: 0 0 10px 10px;
`;

const ChartInner = styled.div`
  position: relative;
  width: ${_constants.contentWidth}px;
`;

const ChartLegend = styled.div`
  display: flex;
  position: relative;
  padding: 0 20px;
  margin: 30px 0 25px 0;

  &:after {
    content: "";
    position: absolute;
    bottom: 8px;
    left: 0;
    background-color: #ccc;
    width: 100%;
    height: 1px;
  }
`;

const LegendYear = styled.div`
  position: relative;
  color: #ccc;
  flex: 1;
  text-align: center;
  z-index: 1;

  &:first-child {
    /* text-align: left; */
  }

  &:last-child {
    /* text-align: right; */
  }

  span {
    background-color: #fff;
    padding: 0 10px;
    color: #a3a3a3;
  }
`;

const AboveGroundLevel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - ${_constants.groundHeight}px);
`;

const EvilMushroomWrap = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  animation: evil-mushroom-movement 35s linear infinite;
`;

const HillBig = styled.div`
  position: absolute;
  bottom: 0;
  left: 4%;
  width: 285px;
  height: 125px;
  background-image: url(./images/svg/hill-big.svg);
  background-size: 285px;
  background-repeat: no-repeat;
`;

const HillSmall = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 185px;
  height: 73px;
  background-image: url(./images/svg/hill-small.svg);
  background-size: 185px;
  background-repeat: no-repeat;
`;

const BushSingle = styled.div`
  position: absolute;
  bottom: 0;
  left: 24%;
  width: 85px;
  height: 43px;
  background-image: url(./images/svg/bush-single.svg);
  background-size: 85px;
  background-repeat: no-repeat;
`;

const BushDouble = styled.div`
  position: absolute;
  bottom: 0;
  left: 41%;
  width: 134px;
  height: 43px;
  background-image: url(./images/svg/bush-double.svg);
  background-size: 134px;
  background-repeat: no-repeat;
`;

const SmallPipe = styled.div`
  position: absolute;
  bottom: 0;
  right: 27%;
  width: 113px;
  height: 113px;
  background-image: url(./images/svg/pipe-small.svg);
  background-size: 113px;
  background-repeat: no-repeat;
`;

const BigPipe = styled.div`
  position: absolute;
  bottom: 0;
  right: 7%;
  width: 113px;
  height: 170px;
  background-image: url(./images/svg/pipe-big.svg);
  background-size: 113px;
  background-repeat: no-repeat;
`;

const CloudSingle = styled.div`
  position: absolute;
  top: 7%;
  left: 7%;
  width: 106px;
  height: 106px;
  background-image: url(./images/svg/cloud-single.svg);
  background-size: 106px;
  background-repeat: no-repeat;
`;

const CloudDouble = styled.div`
  position: absolute;
  top: 10%;
  right: 7%;
  width: 160px;
  height: 80px;
  background-image: url(./images/svg/cloud-double.svg);
  background-size: 160px;
  background-repeat: no-repeat;
`;

const CloudTriple = styled.div`
  position: absolute;
  top: 4%;
  right: 31%;
  width: 210px;
  height: 79px;
  background-image: url(./images/svg/cloud-triple.svg);
  background-size: 210px;
  background-repeat: no-repeat;
`;

const BricksWithCoin = styled.div`
  position: absolute;
  bottom: 300px;
  right: 1%;
  width: 285px;
  height: 57px;
  background-image: url(./images/svg/two-mistery-boxes-bricks.svg);
  background-size: 285px;
  background-repeat: no-repeat;
`;
