import { useStoreMe, setStoreMe } from "store-me";
import { useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { Route } from "react-router-dom";
import styled from "styled-components";

import loadResources from "./utils/loadResources";
import { isMobile } from "./utils/responsive";
import GoodBye from "./components/GoodBye";

const websiteResourcesToPreload = [
  "/images/svg/bush-single.svg",
  "/images/svg/bush-double.svg",
  "/images/svg/cloud-double.svg",
  "/images/svg/cloud-single.svg",
  "/images/svg/cloud-triple.svg",
  "/images/svg/evil-mushroom-step-left.svg",
  "/images/svg/evil-mushroom-step-right.svg",
  "/images/svg/ground.svg",
  "/images/svg/hill-big.svg",
  "/images/svg/hill-small.svg",
  "/images/svg/mario-jump.svg",
  "/images/svg/mario-still.svg",
  "/images/svg/mistery-box.svg",
  "/images/svg/mistery-box-hit.svg",
  "/images/svg/pipe-big.svg",
  "/images/svg/pipe-small.svg",
  "/images/svg/two-mistery-boxes-bricks.svg",
  "/images/svg/coin-sprite.svg",
];

const App = () => {
  const { resourcesLoaded } = useStoreMe("resourcesLoaded");
  const history = useHistory();
  const historyRef = useRef(history);

  useEffect(() => {
    historyRef.current = history;
  }, [history]);

  useEffect(function loadStaticFiles() {
    loadResources(websiteResourcesToPreload)
      .then(() => setStoreMe({ resourcesLoaded: true }))
      .catch(() => {});
  }, []);

  return (
    <Route>
      {!isMobile && resourcesLoaded && <GoodBye />}
      {!isMobile && !resourcesLoaded && <div />}
      {isMobile && (
        <NoMobile>
          <div>Sorry, there is no mobile version for this page.</div>
          <br />
          <div>Please go to a bigger screen.</div>
        </NoMobile>
      )}
    </Route>
  );
};

export default App;

const NoMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100vh;
  padding: 20px;
`;
