import { useStoreMe, setStoreMe, getStoreMe } from "store-me";
import styled, { css, keyframes } from "styled-components";
import { useEffect, useState, useRef } from "react";
import cn from "classnames";

import getFigurePositions from "../utils/getFigurePositions";
import _constants from "./_constants";

const getAnimationKeyframes = (prevTopPosition, targetTopPosition) => {
  let animationFrames;

  if (prevTopPosition >= targetTopPosition) {
    animationFrames = keyframes`
      0% {
        transform: translateY(${prevTopPosition}px);
      }
      50% {
        transform: translateY(${
          targetTopPosition - _constants.candleBlockSize
        }px);
      }
      100% {
        transform: translateY(${targetTopPosition}px);
      }
    `;
  } else {
    animationFrames = keyframes`
      0% {
        transform: translateY(${prevTopPosition}px);
      }
      50% {
        transform: translateY(${
          prevTopPosition - _constants.candleBlockSize
        }px);
      }
      100% {
        transform: translateY(${targetTopPosition}px);
      }
    `;
  }

  return css`
    animation: ${animationFrames} ${_constants.figureAnimationDurationMS}ms ease
      forwards;
  `;
};

const FigureJump = () => {
  const { figureGrid, prevTopPosition, moveCandles, targetTopPosition } =
    useStoreMe(
      "figureGrid",
      "prevTopPosition",
      "moveCandles",
      "targetTopPosition"
    );
  const [canAnimate, setCanAnimate] = useState(false);
  const animationRef = useRef(
    getAnimationKeyframes(prevTopPosition, targetTopPosition)
  );

  useEffect(() => {
    if (moveCandles) {
      const { firstIndexByColumn, targetTopPosition } = getStoreMe(
        "firstIndexByColumn",
        "targetTopPosition"
      );
      const newState = {
        prevTopPosition: targetTopPosition,
        targetTopPosition: getFigurePositions(firstIndexByColumn),
      };

      animationRef.current = getAnimationKeyframes(
        newState.prevTopPosition,
        newState.targetTopPosition
      );

      setStoreMe(newState);
      setCanAnimate(true);
    }
  }, [moveCandles]);

  return (
    <Wrap>
      <GridWrap>
        <GridInner>
          {figureGrid.map((column, columnIndex) => {
            return (
              <Column key={columnIndex}>
                {[...column, 1].map((block, blockIndex) => {
                  if (columnIndex === 2 && blockIndex === 0) {
                    return (
                      <Figure
                        className={cn({ jumping: canAnimate })}
                        style={{
                          transform: `translateY(${targetTopPosition}px)`,
                        }}
                        animation={canAnimate && animationRef.current}
                        prevTopPosition={prevTopPosition}
                        targetTopPosition={targetTopPosition}
                        onAnimationEnd={() => setCanAnimate(false)}
                        key={blockIndex}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </Column>
            );
          })}
        </GridInner>
      </GridWrap>
    </Wrap>
  );
};

export default FigureJump;

const Wrap = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const GridWrap = styled.div`
  position: relative;
`;

const GridInner = styled.div`
  display: flex;
  width: 100%;
  padding-top: ${_constants.candleBlockSize + 1}px;
  padding-bottom: ${_constants.candleBlockSize}px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: ${_constants.candleBlockSize + _constants.candleDistance}px;
  min-width: ${_constants.candleBlockSize + _constants.candleDistance}px;
`;

const Figure = styled.div`
  position: absolute;
  width: ${_constants.candleBlockSize}px;
  height: ${_constants.candleBlockSize}px;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url(./images/svg/mario-still.svg);

  &.jumping {
    background-image: url(./images/svg/mario-jump.svg);
  }

  ${({ animation }) => animation};
`;
