import randomBetween from "./randomBetween";

export const generateCandle = (rows) => {
  const result = [];
  const candle = {
    filledRows: randomBetween(2, rows - 2),
    startsFromIndex: 0,
  };
  const availableSpace = rows - candle.filledRows;

  if (availableSpace === 1) {
    candle.startsFromIndex = randomBetween(0, 1);
  } else if (availableSpace === 2) {
    candle.startsFromIndex = randomBetween(0, 2);
  } else if (availableSpace > 2) {
    candle.startsFromIndex = randomBetween(1, availableSpace - 1);
  }

  for (let n = 0; n < rows; n++) {
    const block =
      n < candle.startsFromIndex ||
      n > candle.startsFromIndex + candle.filledRows - 1
        ? 0
        : 1;

    result.push(block);
  }

  return result;
};

export const recursiveCreateUniqueCandle = (grid, rows, forColumnIndex) => {
  if (forColumnIndex === 0) {
    return generateCandle(rows);
  } else {
    let newCandle = generateCandle(rows);
    const prevCandle = grid[forColumnIndex - 1];
    const prevCandleLength = prevCandle.filter(Boolean).length;
    const newCandleLength = newCandle.filter(Boolean).length;
    const prevCandleStartIndex = prevCandle.indexOf(1);
    const newCandleStartIndex = newCandle.indexOf(1);
    const prevCandleLastIndex = prevCandle.lastIndexOf(1);
    const newCandleLastIndex = newCandle.lastIndexOf(1);

    if (
      newCandleLength === prevCandleLength ||
      newCandleStartIndex === prevCandleStartIndex ||
      newCandleLastIndex === prevCandleLastIndex
    ) {
      return recursiveCreateUniqueCandle(grid, rows, forColumnIndex);
    }

    return newCandle;
  }
};

export const generateInitialGrid = (columns, rows) => {
  const result = [];

  for (let i = 0; i < columns; i++) {
    result.push(recursiveCreateUniqueCandle(result, rows, i));
  }

  return result;
};

export const generateState = (
  grid,
  isInitial,
  prevColorsByColumn,
  prevCandleFitilByColumn = []
) => {
  const state = {
    grid,
    firstIndexByColumn: {},
    colorsByColumn: {},
    candleFitilByColumn: prevCandleFitilByColumn,
  };

  if (isInitial) {
    state.colorsByColumn["0"] = "green";
  } else if (prevColorsByColumn) {
    state.colorsByColumn["0"] = prevColorsByColumn["1"];
  }

  state.grid.forEach((column, columnIndex) => {
    const isLastColumn = state.grid.length - 1 === columnIndex;
    const columnFilledBlocks = column.filter(Boolean).length;

    column.forEach((block, blockIndex) => {
      if (
        typeof state.firstIndexByColumn[columnIndex] === "undefined" &&
        block
      ) {
        state.firstIndexByColumn[columnIndex] = blockIndex;

        if (columnIndex !== 0) {
          const prevFirstIndex = state.firstIndexByColumn[columnIndex - 1];

          state.colorsByColumn[columnIndex] =
            prevFirstIndex > blockIndex ? "green" : "red";
        }
      }
    });

    if (isInitial || (!isInitial && isLastColumn)) {
      const candleFitilHeight = randomBetween(
        columnFilledBlocks,
        column.length - 1
      );
      state.candleFitilByColumn.push({
        height: candleFitilHeight,
        startIndex: randomBetween(
          state.firstIndexByColumn[columnIndex] - candleFitilHeight,
          state.firstIndexByColumn[columnIndex] + columnFilledBlocks
        ),
      });
    }
  });

  if (!isInitial) {
    state.candleFitilByColumn = [
      ...state.candleFitilByColumn.slice(1, state.candleFitilByColumn.length),
    ];
  }

  return state;
};
