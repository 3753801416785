import { createGlobalStyle } from "styled-components";

import _constants from "../components/_constants";
import normalizeCSS from "./normalizeCSS";

/* Note that font family CSS is located in public/index.html file */

const GlobalStyle = createGlobalStyle`
  ${normalizeCSS}

  html,
  body {
    height: 100%;
    margin: 0;
    line-height: 1;
    background-color: #5f80fd;
  }

  * {
    box-sizing: border-box;
    user-select: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  #root {
    position: relative;
    min-height: 100%;
  }

  &::-webkit-scrollbar {
    width: 7px;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f2f9f5;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #83b8a6;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #4f8c77;
  }

  @keyframes move-animation {
    0% { transform: translateX(0px); }
    100% { transform: translateX(-${
      _constants.candleBlockSize + _constants.candleDistance
    }px); }
  }

  @keyframes coin-spin {
    0% {
      background-position: 0px 0px;
    }
    33.2% {
      background-position: 0px 0px;
    }
    33.33333% {
      background-position: -64px 0px;
    }
    66.5% {
      background-position: -64px 0px;
    }
    66.66666% {
      background-position: -127px 0px;
    }
    99.9% {
      background-position: -127px 0px;
    }
    100% {
      background-position: -192px 0px;
    }
  }

  @keyframes coin-jump {
    0% { 
      transform: translateY(0px);
    }
    50% { 
      transform: translateY(-170px);
    }
    100% { 
      transform: translateY(0px);
    }
  }

  @keyframes fade-in {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes fade-in-slide-up {
    0% { opacity: 0; transform: translateY(100%); }
    100% { opacity: 1; transform: translateY(0); }
  }

  @keyframes fade-out {
    0% { opacity: 1; }
    100% { opacity: 0; }
  }

  @keyframes evil-mushroom-movement {
    0% {
      transform: translateX(50px);
    }
    100% {
      transform: translateX(-120vw);
    }
  }
`;

export default GlobalStyle;
